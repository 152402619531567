
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');

body {
   font-family: "Roboto", sans-serif !important;
   margin-bottom: 80px !important;
}

:root {
  --primary-white: #ffffff;
  --primary-orange: #f9ab00;
  --primary-dark: rgba(250,250,250,0);
  --primary-red: #ef4035;
  --primary-hover-red: #683a2e;
  --primary-pink: #ff4081;


}

/***************************** NAVBAR *****************************/

.logo {
  height: 40vmin;
  pointer-events: none;
}

/********  MENU LINKS ***********/

nav a.nav-link {
  letter-spacing: 0.0375rem;
  cursor: pointer;
}

nav ul li {
  text-transform: uppercase;
}

.navbar-nav.fixed-bottom > li > a {
  color: #dedede !important;
  border-top: 0.1875rem solid var(--primary-dark);
  margin-right: 0.75rem;
  margin-left: 0.075rem;
}

.navbar-nav.fixed-bottom > li > a svg {
  color: #dedede !important;
}

/* .navbar-brand {
  max-width: 17.25rem;
  padding: 0 !important;
  height: 6rem !important;
} */

.navbar-nav.fixed-bottom > li > a.active, .navbar-nav > li > a.active:focus, .navbar-nav.fixed-bottom > li > a:hover {
  border-top: 0.1875rem solid var(--primary-orange);
}

@media(max-width: 768px) {
  nav.fixed-bottom .navbar-nav {
    flex-direction: row !important;
  }
  .navbar-nav a.nav-link span {
    display: none !important;
  }
  
}

/* .navbar-dark .navbar-nav .nav-link.active {
  color: #222 !important;
} */

/***************************** HEADER *****************************/
nav {
  z-index: 2;
}

.header-wraper {
  position: relative;
  background: url(/src/images/header-background.jpg) no-repeat;
  background-size: cover;
  background-position: center;
  height: 90vh;
}

@media(max-width: 768px) {
  .header-wraper {
    height: 30vh;
  }
}

.main-info {
  background: rgba(0,0,0,0.7);
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main-info > .title {
  background: rgba(0,0,0,0.3);
  padding: 20px 40px;
  min-width: 800px;
  border: 1px solid var(--primary-white);
}

.main-info h1{
  font-size: 80px;
  line-height: 90px;
  color:  var(--primary-white);
  text-transform: uppercase;
  font-family: "Open Sans",sans-serif;
  
}

@media(max-width: 768px) {
  .main-info > .title {
    padding: 10px 5px;
    min-width: 250px;
  }
  
  .main-info h1{
    font-size: 1.5rem !important;
    line-height: 60px;
  }
}

/* ------ Typed Text -------*/

.typed-text {
  font-size: 2rem;
  font-weight: 300;
  color: var(--primary-white);
}

@media(max-width: 768px) {
  .typed-text {
    font-size: 1.3rem;
  }
}

.btn-main-offer {
  border: 1px solid var(--primary-red);
  border-radius: 0;
  text-transform: uppercase;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  color: var(--primary-white);
  margin: 2rem;
  text-decoration: none;
  z-index: 2;
}

.btn-main-offer:hover {
  background-color: var(--primary-hover-red) !important;
  color: var(--primary-white);
  transition: .2s ease-in-out;
}

/* ------ Particles -------*/
.tsparticles-canvas-el {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: 1;
}

@media(max-width: 768px) {
  .particles-canvas {
    display: none;
  }
}

/* ------ About ME -------*/
.about {
  background-color: rgb(97, 129, 182);
  color: var(--primary-white);
}

.about > h2 {
  font-size: 1.5rem !important;
  text-align: center;
  padding: 40px 0;
  font-family: Open Sans,sans-serif;
}

.about > h2 > small {
  font-size: 1rem !important;
  font-weight: 300;
}

.about .card {
  background: transparent;
  border: 0;
}

.about .card-title {
  font-family: Open Sans,sans-serif;
  font-size: 24px;
}

.about img {
  border-radius: 100%;
  max-width: 320px;
  border: 2px solid var(--primary-white);
}

@media(max-width: 768px) {
  .about img {
    max-width: 150px;
  }
}


/* ------ Experience -------*/
.experience {
  background-color: #ddd;
}

.experience > h2 {
  color: var(--primary-pink);
  font-size: 1.5rem !important;
  text-align: center;
  font-family: Open Sans,sans-serif;
  padding: 40px 0;
}

.experience > h2 > p {
  color: #222;
}

/* ------ Portfolio -------*/

.portfolio > h2 {
  color: var(--primary-pink);
  font-size: 1.5rem !important;
  text-align: center;
  font-family: Open Sans,sans-serif;
  padding: 40px 0;
  margin-top: 20px;
  margin-bottom: 40px;
}

.portfolio > h2 > p {
  color: #222;
}

.card-img-top.php{
  background: rgb(97, 129, 182); 
  width: 100px; height: 100px; 
  margin: 0px auto;
  border-radius: 100%;
  padding: 0 20px;
}

.card-img-top.js{
  background:rgb(240, 219, 79);
  width: 100px; height: 100px; 
  margin: 0px auto;
  border-radius: 100%;
  padding: 25px 4px;
}

.card-img-top.db{
  background:rgb(217, 20, 4);
  width: 100px; height: 100px; 
  margin: 0px auto;
  border-radius: 100%;
  padding: 25px 4px;
}

.card-img-top.other{
  background:rgb(1, 155, 198);
  width: 100px; height: 100px; 
  margin: 0px auto;
  border-radius: 100%;
  padding: 25px 4px;
}

/* ------ Tools -------*/

.tools {
  margin-bottom: 20px;
}

.tools > h2 {
  color: var(--primary-pink);
  font-size: 1.5rem !important;
  text-align: center;
  font-family: Open Sans,sans-serif;
  padding: 20px 0;
  margin-top: 30px;
  margin-bottom: 20px;
}

.tools > h2 > p {
  color: #222;
}


/* ------ Projects -------*/
.projects {
  background-color: #ddd;
}

.projects > h2 {
  color: var(--primary-pink);
  font-size: 1.5rem !important;
  text-align: center;
  font-family: Open Sans,sans-serif;
  padding: 40px 0;
}

.projects > h2 > p {
  color: #222;
}

.vertical-timeline-element--pr.top > .vertical-timeline-element-content{
  border-top: 3px solid #61dafb;
}
.vertical-timeline-element--pr.top.php > .vertical-timeline-element-content{
  border-top: 3px solid rgb(97, 129, 182);
}

.vertical-timeline-element--pr.top.magento > .vertical-timeline-element-content {
  border-top-color: rgb(244, 111, 37);
}

.vertical-timeline-element--pr.top.shopify > .vertical-timeline-element-content {
  border-top-color: #96bf48;
}

.vertical-timeline-element--pr.top.laravel > .vertical-timeline-element-content {
  border-top-color: #F05340;
}

.projects .VerticalTimelineElement .vertical-timeline-element-icon .SVGInline svg {
  width: 36px;
  height: 36px;
  left: auto;
  top: auto;
  margin-left: 0;
  margin-top: 0;
}

.ResumeProjectsBlock-technologies .badge {
  padding: .75em .75em;
  margin-right: 3px;
  background: #e0e0e0 !important;
  color: #222;
  font-weight: 300;
}

.ResumeProjectsBlock-technologies {
  margin-bottom: 5px;
}

.vertical-timeline-element-title {
    font-family: "Open Sans",sans-serif;
    font-size: 24px;
    line-height: 30px;
    font-weight: 400;
    margin-bottom: 10px !important;
}

.vertical-timeline-element-subtitle{
  font-size: 19px;
  font-weight: 600;
  font-family: "Open Sans",sans-serif;
}

.vertical-timeline-element--pr > .vertical-timeline-element-content > p {
  margin-bottom: 20px !important;
  font-weight: 500;
  color: #333;
  
}

/* ------ Contact -------*/
.contact {
  background-color: #fff;
}

.contact > h2 {
  color: var(--primary-pink);
  font-size: 1.5rem !important;
  text-align: center;
  font-family: Open Sans,sans-serif;
  padding: 20px 0;
}

